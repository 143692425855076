console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();



    setTimeout( function(){
        var cardColors = ['bg-green', 'bg-red', 'bg-wine', 'bg-pink'];
        var prevColors = "";

        var placeIcon = ['icon-tl', 'icon-br'];

        var cardIcons = [ 'icon-smile', 'icon-wink', 'icon-ninja', 'icon-heart', 'icon-trust' ];
        var prevIcons = "";

        if ($('.testimonies').length) {

            $('.testimonies').each(function() {

                var arrayColors = [];
                for (var i = 0; i < cardColors.length; i++) {
                    if (cardColors[i] !== prevColors) {
                        arrayColors.push(cardColors[i]);
                    }
                }

                var arrayIcons = [];
                for (var i = 0; i < cardIcons.length; i++) {
                    if (cardIcons[i] !== prevIcons) {
                        arrayIcons.push(cardIcons[i]);
                    }
                }

                $(this).addClass(prevColors =arrayColors[Math.floor(Math.random()*arrayColors.length)]);
                // console.log(prevColors);

                $(this).addClass(placeIcon[ Math.floor( Math.random() * 2 ) ]);

                $(this).addClass(prevIcons =arrayIcons[Math.floor(Math.random()*arrayIcons.length)]);
                // console.log(prevIcons);
            });

            $('#home-6 .wp-block-column:nth-child(1) ul.wp-block-post-template').clone().appendTo('#home-6 .wp-block-column:nth-child(1) .wp-block-query');
            $('#home-6 .wp-block-column:nth-child(2) ul.wp-block-post-template').clone().appendTo('#home-6 .wp-block-column:nth-child(2) .wp-block-query');
            $('#home-6 .wp-block-column:nth-child(3) ul.wp-block-post-template').clone().appendTo('#home-6 .wp-block-column:nth-child(3) .wp-block-query');
        }
    }, 1 );


    $('.wp-block-group.loops').clone().appendTo('.loop-barrier > .wp-block-group__inner-container');

    // $( window ).on( "load", function() {

    // } );


    $("#landing-links").submit(function(e){
        e.preventDefault();
            location.href = $('#landing-links select').val();
        });


});